<template>
  <div class="grid grid-cols-12" :class="rowGap">
    <label v-for="(optionName, index) in options" :key="index" class="flex items-center cursor-pointer"
      :class="[colSpan, disabled ? 'cursor-not-allowed' : '']" ref="radioBtn" @click="selectChecked(index)"
      :style="`${editOption.id === optionName.id ? 'height: 54px; margin-top: -5px' : null}`">
      <input @input="changeButton" :value="optionName.value" type="radio" :disabled="disabled" @change="handleChange"
        :name="optionName.radioName || radioName" class="hidden" :checked="value === optionName.value" />
      <span class="w-4 h-4 inline-block rounded-full bg-cultured" :class="[textSize, spaceBetween]" />
      <span v-if="!editRadio">{{ optionName.name }}</span>
      <span v-if="editOption.id !== optionName.id && editRadio">{{ optionName.name }}</span>
      <c-text placeholder="--Enter--" variant="w-full -mt-3 mb-10" style="height: 20px !important"
        v-if="editOption.id === optionName.id && editRadio" v-model="editOption.name" />
      <span class="w-auto text-romanSilver ml-2 mr-5 mt-1 font-semibold uppercase" style="font-size: 10px"
        v-if="optionName.subtitle">
        {{ optionName.subtitle }}
      </span>
    </label>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";

export default {
  name: "RadioButton",
  components: { CText },
  props: {
    label: {
      default: "",
      type: String,
    },
    editOption: {
      default: "",
      type: String,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    editRadio: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    colSpan: {
      type: String,
      default: "col-span-6",
    },
    spaceBetween: {
      type: String,
      default: "mr-5",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: null,
    },
    checked: {
      type: Boolean,
    },
    textSize: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    rowGap: {
      type: String,
      default: "gap-y-6",
    },
    radioName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editOptionValue: ""
    };
  },
  watch: {
    cancel(val) {
      if (val) {
        for (let i = 0; i < this.$refs.radioBtn.length; i++) {
          this.$refs.radioBtn[i].children[0].checked = false;
          this.$refs.radioBtn[i].classList.remove("hover-radioBtn");
        }
        this.$emit("resetCancel", false);
      }
    },
  },
  computed: {
    radioButtonValue: {
      get() {
        return this.value;
      },
      set() {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit("change", this.label);
      },
    },
  },
  methods: {
    changeButton(value) {
      // console.log(value.target.value);
      this.$emit("input", value.target.value);
    },
    handleChange(value) {
      this.$emit("change", value.target.value);
    },
    selectChecked(index) {
      if (this.hover) {
        this.$refs.radioBtn[index].classList.add("hover-radioBtn");
        for (let i = 0; i < this.$refs.radioBtn.length; i++) {
          if (i !== index) {
            this.$refs.radioBtn[i].classList.remove("hover-radioBtn");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.hover-radioBtn {
  padding: 16px;
  background-color: #f5f5f5;
  box-shadow: 0px 10px 15px rgba(8, 7, 8, 0.1),
    0px 4px 6px rgba(45, 49, 66, 0.05);
}

label input[type="radio"] + span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + span:hover,
label:hover input[type="radio"] + span {
  transform: scale(1.2);
}

input[type="radio"]:checked + span {
  background-color: #f15a29;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
